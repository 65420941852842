<template>
  <div class="vm-news-wrap" id="contact-page">
    <div class="vuma-contact-us-page-wrapper">
      <div
        class="vm-section vm-articles"
        style="overflow: auto; padding-top: 4rem;"
      >
        <v-container>
          <v-row align="center">
            <v-col align="left" justify="left" cols="12" sm="6">
              <div class="text-left" style="font-size: 25px;">
                <h1 style="font-weight: lighter;">
                  Contact Us
                </h1>
              </div>
              <p class="mt-4">
                Our team is ready to assist you with your questions.
              </p>
              <p
                class="mt-10"
                style="display: flex; flex-direction: column; gap: 5px;"
              >
                <span>Phone:</span>
                <span>010 746 8862</span>
              </p>
              <p
                class="mt-10"
                style="display: flex; flex-direction: column; gap: 5px;"
              >
                <span>Hours:</span>
                <span>Mon-Fri 8am - 5pm</span>
              </p>

              <div
                class="social-media-buttons"
                style="gap: 2rem; display: flex; margin-top: 4rem;"
              >
                <v-btn
                  icon
                  outlined
                  size="small"
                  small
                  link
                  href="https://www.linkedin.com/company/vumatel/"
                  target="_blank"
                  ><v-icon medium>mdi-linkedin</v-icon></v-btn
                >
                <v-btn
                  icon
                  outlined
                  size="small"
                  small
                  link
                  href="https://twitter.com/vumatel"
                  target="_blank"
                  ><v-icon medium>mdi-twitter</v-icon></v-btn
                >
                <v-btn
                  icon
                  outlined
                  size="small"
                  small
                  link
                  href="https://www.facebook.com/profile.php?id=100064644344060"
                  target="_blank"
                  ><v-icon medium>mdi-facebook</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div class="network-status-wrapper">
      <v-container>
        <div
          style="display: flex; align-items: center; justify-content: space-between;"
        >
          <h3 style="padding: 0!important">Check your network status</h3>
          <v-btn
            type="link"
            to="/network-status"
            rounded
            outlined
            class="white-outline"
          >
            Check status
          </v-btn>
        </div>
      </v-container>
    </div>
    <div class="vm-section vm-articles" style="overflow: auto;">
      <v-container>
        <v-row align="center">
          <v-col align="left" justify="left" cols="12" sm="6">
            <div class="text-left" style="font-size: 25px;">
              <h1 style="font-weight: lighter;">
                Get in touch with your Internet Service Provider
              </h1>
            </div>
            <p class="mt-8">
              If you have query about fibre and would like to reach out and
              connect with someone to discuss it further, we highly recommend
              that you get in touch with your Internet Service Provider as they
              can offer fast and efficient support on your particular fibre
              line. We are here to support them and you, at all stages, because
              together we're all connected.
            </p>
          </v-col>
          <v-col align="right" justify="right" class="d-flex" cols="12" sm="6">
            <div
              class="v-input v-input--hide-details theme--light v-text-field v-text-field--single-line v-text-field--is-booted"
            >
              <div class="v-input__control">
                <div class="v-input__slot search-bar">
                  <div class="v-text-field__slot">
                    <input
                      id="searchbar"
                      placeholder="Search ISPs"
                      class="search-bar-input"
                      type="text"
                      v-model="keyword"
                    />
                  </div>
                  <div class="v-input__append-inner">
                    <button class="v-input__icon v-input__icon--append">
                      <i
                        aria-hidden="true"
                        class="search-bar-icon v-icon notranslate mdi mdi-magnify theme--light searchbar-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
          <div
            class="d-none d-sm-block"
            style="width: 100%;"
            v-if="this.apiProviders.length > 0"
          >
            <table class="isp-table">
              <tbody>
                <tr v-for="item in filteredItems" :key="item.id">
                  <td class="text-start">
                    <a :href="item.website" target="_blank">{{
                      item.serviceprovider
                    }}</a>
                  </td>
                  <td class="text-start">
                    <a :href="'tel:' + item.phone" target="_blank">{{
                      item.phone
                    }}</a>
                  </td>
                  <td class="text-start">
                    <a :href="'mailto:' + item.email" target="_blank">{{
                      item.email
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mobile-isp d-block d-sm-none">
            <div class="isp-item" v-for="item in filteredItems" :key="item.id">
              <h4>{{ item.serviceprovider }}</h4>
              <p>
                <a :href="'tel:' + item.phone" target="_blank">{{
                  item.phone
                }}</a
                ><br />
                <a :href="'mailto:' + item.email" target="_blank">{{
                  item.email
                }}</a
                ><br />
                <a :href="item.website" target="_blank">{{ item.website }}</a>
              </p>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>
    <div class="network-status-wrapper">
      <v-container>
        <div
          style="display: flex; align-items: center; justify-content: space-between;"
        >
          <h3 style="padding: 0!important">Frequently Asked Questions</h3>
          <v-btn
            type="link"
            to="/faqs"
            rounded
            outlined
            class="white-outline"
          >
            Check FAQs
          </v-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'VumaContact',
  data: () => ({
    apiProviders: [],
    keyword: '',
  }),
  mounted() {
    $('.vuma-menu, .menu-overlay').removeClass('menu-show');
    $('html').css('overflow', 'auto');
    this.scrollTop();

    this.getProviders()
  },
  computed: {
    filteredItems() {
      const filteredThings = this.apiProviders.filter((item) => {
        return (
          item.serviceprovider && (item.phone || item.email || item.website)
        );
      });
      const keyword = this.keyword.toUpperCase();
      return keyword.trim() === ''
        ? filteredThings
        : filteredThings.filter((item) =>
            item.serviceprovider.toUpperCase().includes(keyword)
          );
    },
  },
  methods: {
    formSubmit() {
      this.$cookies.set('contactname', this.name);
      this.$router.push({ name: 'ThankYou' });
    },
    scrollTop() {
      let target = 'html, body';
      let $target = $(target);

      $('html, body')
        .stop()
        .animate(
          {
            scrollTop: $target.offset().top,
          },
          500,
          'swing'
        );
    },
    groupByAlpha(providers) {
      let data = providers.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});

      return Object.values(data);
    },
    getProviders (key) {
      const headers = {
        apikey: process.env.VUE_APP_GET_CONTACT_DETAILS_API_KEY
      }

      this.axios
        .get(
          `${process.env.VUE_APP_PANGEA_BASE_URL}/users/get_isp_contact_details/`,
          { headers: headers }
        )
        .then((response) => {
          if (response?.data?.detail?.length) {
            response.data.detail.map((item) => {
              item.website = this.getCompleteUrl(item.website);
            });
          }
          this.apiProviders = response.data.detail;
        });
    },
    getCompleteUrl(url) {
      if (!url) return;
      return url.includes('https') || url.includes('http') ? url : `//${url}`;
    },
  },
  metaInfo() {
    return {
      title: 'Contact Your ISP | Get In Touch With Your ISP For Support | Vuma',
      meta: [
        {
          name: 'description',
          content:
            "Contact Vuma or any of our partners. Get in touch with your Internet Service Provider for fast and efficient support. Together we're connected.",
        },
        {
          property: 'og:title',
          content: 'Contact Us | Get In Touch With Your ISP For Support | Vuma',
        },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
      ],
    };
  },
};
</script>

<style lang="scss">
#contact-page {
  padding-top: 99px;

  .vm-featured-news {
    padding-top: 0;

    &.contact-bg {
      min-height: 750px;
      padding-top: 3rem;
      background-size: cover;
      background-position: top right;

      img {
        display: none;
      }
    }
  }

  .h3-isp {
    font-size: 63px;
    margin-bottom: 3rem;
    font-weight: lighter;
    max-width: 600px;
    width: 100%;
    line-height: 1;
  }

  .contact-h1 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #000;
  }

  .intro-contact {
    max-width: 560px;
    width: 100%;
  }

  .contact-form-wrap {
    border-radius: 30px;
    margin-top: 8%;
    padding: 1rem 2.5rem;
  }

  .v-alert.success {
    background-color: #17183b !important;
    border-color: #17183b !important;
  }
}

.txtarea-custom {
  resize: none !important;
}

.v-application .py-10 {
  padding: 0 35px 10px !important;
}

#contact-form .v-input {
  .v-label {
    height: auto;
    line-height: initial;
    font-size: 20px;

    &.v-label--active {
      top: 0 !important;
    }
  }

  input {
    max-height: initial;
    font-size: 20px;
  }

  textarea {
    resize: none;
    font-size: 20px;
  }
}

#contact-form .v-text-field .v-label {
  top: 10px;
}

#contact-form.v-label {
  font-size: 21px;
}

body .v-btn {
  font-size: 20px !important;
}

.v-btn--fab.v-size--small {
  height: 40px !important;
  width: 40px;
}

.search-bar-input {
  padding: 15px 30px 15px 25px !important;
}

.isp-table {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 3rem;

  a {
    color: #000;
    text-decoration: none;
  }

  tr:hover a {
    color: #ff008e;
  }
}

.v-text-field .v-input__append-inner,
.v-text-field .v-input__prepend-inner {
  margin-top: 12px;
  margin-right: 5px;
}

.contact-us-form-wrapper {
  display: flex;
  flex-direction: column !important;
  display: flex;
  width: 100%;
  padding: 2rem;
  padding-top: 5rem;
  padding-bottom: 0rem;
  gap: 0.5rem;
}

.contact-us-form-input {
  background: #eee;
  border-radius: 5px;
  width: 400px;
  padding: 0.75rem 1rem;
  border: solid 1px transparent;
}

.invalid-form-input {
  border: solid 1px red;
}

.contact-us-form-send-button {
  width: 400px;
}

.form-input-error {
  text-align: left;
  color: red;
  font-size: 16px;
  padding-left: 6px;
  margin-bottom: 10px;
}

.network-status-wrapper {
  width: 100%;
  background: #ee008c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  min-height: 6rem;
}

.network-status-wrapper h3 {
  color: white;
  font-size: 2rem;
  font-weight: lighter;
  padding: 0 !important;
  padding-top: 0 !important;
}

@media screen and (max-width: 1211px) {
  #contact-page h3 {
    padding-top: 10% !important;
  }
}

body .vuma-header {
  background: #ff008e !important;
}

table {
  border: 0 !important;
  width: 100% !important;
}

th,
td {
  text-align: left !important;
  padding: 16px !important;
  font-size: 19px !important;
}

tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

.search-bar-input {
  max-height: 100% !important;
  line-height: 20px;
  padding: 15px 10px 15px 10px;
  min-width: 0;
  width: 100%;
}

.search-bar-label {
  left: 1vh !important;
  position: absolute;
}

#searchbar::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

#searchbar:-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

#searchbar::-moz-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

#searchbar:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.87);
}

.search-bar-icon {
  color: #ee008c !important;
  font-weight: bold !important;
  margin-bottom: -20% !important;
  font-size: 25px !important;
  right: 12px;
}

.v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
  .v-input__prepend-inner
  .v-input__icon
  > .v-icon,
.v-text-field.v-input--dense:not(.v-text-field--enclosed):not(.v-text-field--full-width)
  .v-input__append-inner
  .v-input__icon
  > .v-icon {
  margin-top: 26px;
  color: #ee008c;
}

.search-bar.v-input__slot {
  border: 1px solid #ee008c !important;
  border-radius: 100px !important;
}

.search-bar.v-input__slot::before {
  border-color: rgba(0, 0, 0, 0) !important;
}

#isp-input {
  padding: 0;
}

#contact-form .v-text-field .v-label[for='isp-input'] {
  top: 0;
}

#contact-form .v-text-field .v-label[for='isp-input'].v-label--active {
  top: -6px !important;
}

.table-btn-tr {
  background: white !important;
}

.table-btn-tr .table-btn {
  padding: 0.3rem 0.3rem !important;
  background: #ff008e !important;
  left: 1vh;
  margin-top: 20px;
  margin-bottom: 5px;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.news-page {
  padding-top: 40px;

  .vuma-header {
    top: 0;
    background: #fff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    a.vm-logo .vuma-logo-white {
      display: none;
    }

    a.vm-logo .vuma-logo-pink {
      display: inline-block;
    }

    .top-nav .v-btn--text {
      color: #ee008c;
    }

    &.fixed-top {
      .top-nav .v-btn--text {
        color: #fff;
      }

      a.vm-logo .vuma-logo-white {
        display: inline-block;
      }

      a.vm-logo .vuma-logo-pink {
        display: none;
      }
    }
  }

  #read-more-news {
    display: none;
  }
}

@media screen and (max-width: 1490px) {
  #contact-page.vm-featured-news .container {
    max-width: 1366px !important;
  }
}

@media screen and (max-width: 1580px) {
  #contact-page .container {
    max-width: 1361px !important;
  }
}

@media screen and (max-width: 1366px) {
  #contact-page .h3-isp {
    font-size: 48px;
    max-width: 400px;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 991px) {
  #contact-form .v-input .v-label,
  #contact-form .v-input input {
    font-size: 18px;
  }
  #contact-page .vm-featured-news.contact-bg {
    min-height: 450px;
  }
}

@media screen and (max-width: 767px) {
  #contact-page {
    padding-top: 80px;
  }
}

@media screen and (max-width: 600px) {
  .mobile-isp {
    margin-top: 1.5rem;
    width: 100%;

    .isp-item {
      padding: 1rem;

      &:nth-child(even) {
        background: rgba(0, 0, 0, 0.05);
      }

      h4 {
        margin-bottom: 1rem;
      }

      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
}

@media screen and (max-width: 590px) {
  #contact-page .contact-form-wrap {
    padding: 1rem 2rem;
  }

  #contact-page {
    padding-top: 60px;
  }
  #contact-page .h3-isp {
    padding-top: 1rem !important;
  }

  #contact-page .vm-featured-news.contact-bg {
    min-height: auto;
    background-image: none !important;
    display: block !important;
    padding-top: 0;
    padding-bottom: 0;
  }

  .network-status-wrapper {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  #contact-page .h3-isp {
    font-size: 36px;
  }

  #contact-page .contact-form-wrap {
    padding: 1rem 1.5rem;
    margin-top: 0;
  }
  #contact-page .contact-form-wrap .v-text-field {
    padding-top: 0;
    margin-top: 0;
  }
}
</style>
